












































































import { Component, Prop, Vue } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";
import { Api } from "@/api";
import { InfoModal } from "@/components";
import { namespace as authNs } from "@/store/auth";
import { ClientSideError } from "@/types";

const signedUpText =
  "You will receive shortly an email with the link you can use to confirm your sign up.";

@Component({
  components: {
    InfoModal,
  },
  mixins: [validationMixin],
  validations: {
    email: { required, email },
  },
})
export default class SignUp extends Vue {
  @Prop({ type: String, required: false }) private params!: string;
  private email = "";

  private checked = false;
  private requesting = false;
  private confirmingRegistration = false;

  private infoModalVisible = false;
  private infoModalProps = { title: signedUpText };

  private async beforeMount(): Promise<void> {
    this.$store.dispatch("signout");
    if (this.params) {
      this.confirmingRegistration = true;
      await this.confirmUserAndRedirect();
    }
  }

  private async confirmUserAndRedirect(): Promise<void> {
    try {
      await this.confirmUser();
      this.$router.push({ name: "dashboard" });
    } catch (error) {
      this.showErrorModalWithAppropriateMessage(error);
      this.confirmingRegistration = false;
    }
  }

  private async confirmUser(): Promise<void> {
    const [email, message, sig] = this.params.split("::");
    const signatureBase64: string = decodeURI(sig);
    await this.$store.dispatch(`${authNs}/confirmNewUser`, {
      email,
      message,
      signatureBase64,
    });
  }

  private showErrorModalWithAppropriateMessage(error: ClientSideError): void {
    switch (error.status) {
      case 401: {
        this.infoModalProps.title =
          "This confirmation code has been used already to register an account. Please sign in to access the console.";
        break;
      }
      case 409: {
        this.infoModalProps.title =
          "Please use an unregistered e-mail to sign up or sign in with an already registered e-mail!";
        break;
      }
      default: {
        this.infoModalProps.title = error.message;
      }
    }
    this.infoModalVisible = true;
  }

  private async onSignUpButtonClick(): Promise<void> {
    this.$v.$touch();
    this.validateForm() ? this.signUp() : null;
  }

  private async signUp(): Promise<void> {
    this.requesting = true;
    try {
      await Api.signUp({ email: this.email });
      this.infoModalProps.title = signedUpText;
      this.infoModalVisible = true;
    } catch (error) {
      this.showErrorModalWithAppropriateMessage(error);
    }
    this.requesting = false;
  }

  private validateForm(): boolean | null {
    return this.validEmail && this.checked;
  }

  private get validBox(): boolean | null {
    if (this.$v.$dirty) {
      return this.checked;
    } else {
      return null;
    }
  }

  private get validEmail(): boolean | null {
    if (this.$v.$dirty) {
      return this.validateEmail();
    } else {
      return null;
    }
  }

  private validateEmail(): boolean {
    return !this.$v.email.$invalid;
  }
}
